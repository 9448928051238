<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div v-if="message_type != 'mysdgs'" class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>MY {{ this.messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>MY {{ this.messagetype.title_jp }}</h2>
      <div class="mascot_wrap" style="display: none">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
      <div
        class="user_img"
        :style="{ backgroundImage: 'url(' + profileImg + ')' }"
        v-if="profileImg"
      ></div>
      <div
        class="user_img"
        :style="{
          backgroundImage:
            'url(' +
            require('@/assets/front_component/images/test/test01.png') +
            ')',
        }"
        v-else
      ></div>
    </div>
  </div>

  <main>
    <div v-if="message_type === 'mysdgs'">
      <SdgsGoalRatio />
    </div>
    <div class="section_all_wrap">
      <div class="content_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <span>MY {{ this.messagetype.title_jp }}一覧</span>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="content_in_wrap">
            <div class="innar">
              <div class="t_l">
                <div class="command mypage_clarea">
                  <router-link
                    :to="{
                      name: 'Control messagecreate',
                      params: { type: message_type },
                    }"
                    class="create"
                    >新規追加</router-link
                  >
                </div>
              </div>
              <div class="js-scrollable">
                <table class="tbl_list">
                  <tr class="sort">
                    <th class="tbl_list_ttl">
                      <div class="sort_ttl"><span>タイトル</span></div>
                    </th>
                    <th class="tbl_list_day t_c">
                      <div class="select_list_wrap">
                        <ul class="select_list">
                          <li class="select_label-placeholder">
                            <span>公開日</span>
                          </li>
                          <li class="select_items">
                            <ul class="select_options">
                              <li class="select_option">
                                <input
                                  v-model="order"
                                  class="select_input"
                                  type="radio"
                                  name="orderchange"
                                  id="order_desc"
                                  v-on:change="onOrderChange"
                                  value="desc"
                                />
                                <label class="select_label" for="order_desc"
                                  >新しい順</label
                                >
                              </li>

                              <li class="select_option">
                                <input
                                  v-model="order"
                                  class="select_input"
                                  type="radio"
                                  name="orderchange"
                                  id="order_asc"
                                  v-on:change="onOrderChange"
                                  value="asc"
                                />
                                <label class="select_label" for="order_asc"
                                  >古い順</label
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </th>
                    <th
                      v-if="
                        messagecolumn['target_ids'] &&
                        message_type != 'column' &&
                        message_type != 'photolog'
                      "
                      class="tbl_list_name t_c"
                    >
                      <div class="sort_ttl"><span>宛先</span></div>
                    </th>
                    <th
                      v-if="messagecolumn['status'] && message_type != 'thanks'"
                      class="tbl_list_sta t_c"
                    >
                      <div class="select_list_wrap">
                        <ul class="select_list">
                          <li class="select_label-placeholder">
                            <span>ステータス</span>
                          </li>
                          <li class="select_items">
                            <ul class="select_options">
                              <li class="select_option">
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_all"
                                  v-on:change="onStatusChange"
                                  value="all"
                                />
                                <label class="select_label" for="status_all"
                                  >全て</label
                                >
                              </li>
                              <li
                                v-if="messagecolumn['status_list'] == '投稿'"
                                class="select_option"
                              >
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_koukai"
                                  v-on:change="onStatusChange"
                                  value="公開"
                                />
                                <label class="select_label" for="status_koukai"
                                  >公開</label
                                >
                              </li>

                              <li
                                v-if="messagecolumn['status_list'] == '投稿'"
                                class="select_option"
                              >
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_shitagaki"
                                  v-on:change="onStatusChange"
                                  value="下書き"
                                />
                                <label
                                  class="select_label"
                                  for="status_shitagaki"
                                  >下書き</label
                                >
                              </li>

                              <li
                                v-if="messagecolumn['status_list'] == '承認'"
                                class="select_option"
                              >
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_shinsei"
                                  v-on:change="onStatusChange"
                                  value="申請中"
                                />
                                <label class="select_label" for="status_shinsei"
                                  >申請中</label
                                >
                              </li>

                              <li
                                v-if="messagecolumn['status_list'] == '承認'"
                                class="select_option"
                              >
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_kyakka"
                                  v-on:change="onStatusChange"
                                  value="却下"
                                />
                                <label class="select_label" for="status_kyakka"
                                  >却下</label
                                >
                              </li>

                              <li
                                v-if="messagecolumn['status_list'] == '承認'"
                                class="select_option"
                              >
                                <input
                                  v-model="status"
                                  class="select_input"
                                  type="radio"
                                  name="statuschange"
                                  id="status_syounin"
                                  v-on:change="onStatusChange"
                                  value="承認済"
                                />
                                <label class="select_label" for="status_syounin"
                                  >承認済</label
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </th>
                    <th class="tbl_list_command">
                      <div class="sort_ttl"><span>操作</span></div>
                    </th>
                  </tr>
                  <tr v-for="message in messages.slice()" :key="message.id">
                    <td class="tbl_list_ttl">
                      <router-link
                        v-if="message_type == 'thanks'"
                        :to="{
                          name: 'Control messageshow',
                          params: { type: message_type, id: message.id },
                          query: { row_count: now_post_num },
                        }"
                      >
                        <span
                          v-if="message.target_user == ''"
                          >--</span>
                        <span
                          v-for="userlist in message.target_user"
                          :key="userlist.id"
                          > {{ userlist.deleted_at ? userlist.last_name + userlist.first_name  + ' (削除済み) ' :userlist.last_name + userlist.first_name }}さん</span
                        >
                        <span v-if="message.target_user != ''"
                          >への{{ message.title }}</span
                        >
                      </router-link>
                      <router-link
                        v-else-if="
                          message.type == 'challenge' || message.type == 'sdgs'
                        "
                        :to="{
                          name: 'Control messageshow',
                          params: { type: message_type, id: message.id },
                          query: { row_count: now_post_num },
                        }"
                      >
                        {{ showYear(message.option) }}
                      </router-link>
                      <!-- v-html="message.option" -->
                      <router-link
                        v-else-if="
                          message.type == 'idea' ||
                          message.type == 'daily' ||
                          message.type == 'photolog'
                        "
                        :to="{
                          name: 'Control messageshow',
                          params: { type: message_type, id: message.id },
                          query: { row_count: now_post_num },
                        }"
                        v-html="message.content"
                      ></router-link>
                      <router-link
                        v-else
                        :to="{
                          name: 'Control messageshow',
                          params: { type: message_type, id: message.id },
                          query: { row_count: now_post_num },
                        }"
                        >{{ message_type == 'mysdgs' ? message.content.content : message.title }}</router-link
                      >
                    </td>
                    <td class="tbl_list_day t_c">
                      <span class="day_time">
                        <img
                          src="@/assets/front_component/images/assets/board01.png"
                          alt="時間"
                        /><time
                          class="en"
                          :datetime="formatDate_time(message.published_at)"
                          >{{ formatDate(message.published_at) }}</time
                        >
                      </span>
                    </td>
                    <td
                      v-if="
                        messagecolumn['target_ids'] &&
                        message_type != 'column' &&
                        message_type != 'photolog'
                      "
                      class="tbl_list_name t_c"
                    >
                      <p v-if="message.target_user.length === 0">
                        -- --
                      </p>
                      <p
                        v-else-if="message.target_user.length !== 0"
                        v-for="userlist in message.target_user"
                        :key="userlist.id"
                      >
                         {{ userlist.deleted_at ? userlist.last_name + userlist.first_name  + ' (削除済み) ' :userlist.last_name + userlist.first_name }}                         
                      </p>
                      <img
                        v-else
                        src="@/assets/images/spinner/spinner.gif"
                        width="25"
                        height="25"
                      />
                    </td>
                    <template
                      v-if="messagecolumn['status'] && message_type != 'thanks'"
                    >
                      <td
                        v-if="message.status == '却下'"
                        class="tbl_list_sta t_c"
                      >
                        <div class="st_popup">
                          <div @click="kyakka_modal(message.id)">
                            <span class="reject-message">{{
                              message.status
                            }}</span>
                            <div class="submit_wrap click_pop">
                              <div
                                style="display: none"
                                enctype="multipart/form-data"
                                autocomplete="off"
                                class="click_popup"
                              >
                                <div class="innar">
                                  <div class="form_outwrap">
                                    <div class="wrap wid_100per">
                                      <p class="ttl_03">
                                        理由
                                        <i
                                          class="fas fa-times close-icon"
                                          @click="
                                            closeRejectMessagePopup($event)
                                          "
                                        ></i>
                                      </p>
                                      <div class="in_wrap input_wrap">
                                        <span class="st_comment">{{
                                          approval_message
                                        }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td v-else class="tbl_list_sta t_c">
                        {{ message.status }}
                      </td>
                    </template>
                    <td class="tbl_list_command">
                      <div class="commandlist">
                        <div class="command" v-if="message.from_admin">
                          <a class="edit not_active">編集</a>
                        </div>
                        <div v-else class="command">
                          <router-link
                            v-if="
                              messagecolumn['status_list'] == '承認' &&
                              message.status != '承認済' &&
                              message.status != '申請中' &&
                              message_type != 'photolog'
                            "
                            class="edit"
                            :to="{
                              name: 'Control messageedit',
                              params: { type: message_type, id: message.id },
                              query: { row_count: now_post_num },
                            }"
                            >編集</router-link
                          >
                          <a
                            v-else-if="messagecolumn['status_list'] == '承認'"
                            class="edit not_active"
                            >編集</a
                          >
                          <!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                            <a
                            v-else-if="message.target_user[0]?.deleted_at"
                            class="edit not_active"
                            >編集</a
                          >
                          <a
                            v-else-if="message.from_user?.deleted_at"
                            class="edit not_active"
                            >編集</a
                          >
                          <router-link
                            v-else
                            class="edit"
                            :to="{
                              name: 'Control messageedit',
                              params: { type: message_type, id: message.id },
                              query: { row_count: now_post_num },
                            }"
                            >編集</router-link
                          >
                        </div>
                        <div class="command" v-if="message.from_admin">
                          <a class="delete not_active" data-tip="削除">削除</a>
                        </div>
                        <div class="command" v-else>
                          <a
                            v-if="
                              messagecolumn['status_list'] == '承認' &&
                              message.status != '承認済'
                            "
                            class="delete delete_button m_l"
                            @click="modalOpenDelete(message.id)"
                            data-tip="削除"
                            >削除</a
                          >
                          <a
                            v-else-if="messagecolumn['status_list'] == '承認'"
                            class="delete not_active m_l"
                            data-tip="削除"
                            >削除</a
                          >
                          <!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                          <a
                            v-else-if="message.target_user[0]?.deleted_at"
                            class="delete not_active m_l"
                            data-tip="削除"
                            >削除</a
                          >
                          <a
                            v-else-if="message.from_user?.deleted_at"
                            class="delete not_active m_l"
                            data-tip="削除"
                            >削除</a
                          >
                          <a
                            v-else
                            class="delete delete_button m_l"
                            @click="modalOpenDelete(message.id)"
                            data-tip="削除"
                            >削除</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <div id="load" style="display: none">
                  <div class="half-circle-spinner">
                    <div class="circle circle-1"></div>
                    <div class="circle circle-2"></div>
                  </div>
                </div>
                <div
                  id="more_btn"
                  class="t_c"
                  v-if="get_post_num < total && now_post_num < total"
                >
                  <div class="btn_wide">
                    <button v-on:click="messageListmore">さらに読み込む</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/bard01.png"
              alt="青鳥"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/bard02.png"
              alt="緑鳥"
            />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'mysdgs' },
            }"
            >SDGsレポート</router-link
          >
        </li>
        <!-- <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
            >お知らせ</router-link
          >
        </li> -->
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >創発カード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>

  <div
    class="modal"
    id="confirmModalCom"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal_inner confirm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form_outwrap">
              <div class="modal_header">
                <p class="ttl_header">確認</p>
              </div>
              <div class="modal_wrap">
                <div class="for_wrap h_80">
                  <div class="modal_contents">
                    <p>
                      <span>投稿を削除しますか？</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal_footer">
                <div class="flex_wrap f_c">
                  <div class="btn_norm reverse" id="cancel_bk">
                    <button
                      name=""
                      value="キャンセル"
                      class="close"
                      dusk="delete_data"
                      @click="closeDiscardCom()"
                    >
                      キャンセル
                    </button>
                  </div>
                  <div class="btn_norm delete">
                    <button
                      @click="deletemessage()"
                      class="btn btn"
                      value="アーカイブ"
                      title="削除"
                      dusk="delete_data"
                    >
                      実行
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        id="close_btn"
        class="pop-remove_btn ttoltip tip-top close"
        @click="closeDiscardCom()"
      >
        <i class="fas fa-times"></i>
      </p>
      <!-- <div class="close_btn tooltip tip-left" title="閉じる">
        <i class="fas fa-times"></i>
      </div> -->
    </div>
    <!--modal_inner-->
  </div>
  <!--modal-->
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import message from "@/apis/Message";
import $ from "jquery";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import dayjs from "dayjs";
import user from "@/apis/User";
import ScrollHint from "scroll-hint";
import striptags from "striptags";
import SdgsGoalRatio from '../sdgs/SdgsGoalRatio';

export default {
  name: "message",
  components: {
    SdgsGoalRatio
  },
  data() {
    return {
      messages: [],
      cretemessage: { target_ids: [] },
      nameError: "",
      emailError: "",
      loader: false,
      showmessage: {},
      editmessage: {},
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      messagetype: {},
      now_post_num: 25,
      get_post_num: 25,
      total: "",
      order: "desc",
      status: "all",
      approval_message: null,
      del_id: "",
      more_load: false,
      profileImg: false,
      resGet: false,
    };
  },
  computed: {},
  watch: {
    $route(to, from) {
      if (from.name == "Control message" && to.name == "Control message") {
        $(function () {
          $("#js-loader").show();
          $(window).scrollTop(0);
        });

        this.message_type = this.$route.params.type;
        this.messagetype = MessageType[this.message_type];
        this.messagecolumn = MessageColumn[this.message_type];
        if (this.messagecolumn == null) {
          this.$router.push({ name: "Front 404" });
        }
        this.messageList(
          this.message_type,
          this.$User.id,
          this.get_post_num,
          this.order,
          this.status
        );
        $(function () {
          $("#js-loader").delay(500).fadeOut(600);
        });
      }
    },
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.get_post_num = this.$route.query.row_count
      ? parseInt(this.$route.query.row_count)
      : 25;

    this.message_type = this.$route.params.type;
    this.messagetype = MessageType[this.message_type];
    this.messagecolumn = MessageColumn[this.message_type];
    if (this.messagecolumn == null) {
      this.$router.push({ name: "Front 404" });
    }
    this.messageList(
      this.message_type,
      this.$User.id,
      this.get_post_num,
      this.order,
      this.status
    );
    this.profileImgApi();
    if (localStorage.getItem("messageType") || localStorage.getItem("messagewithUsers")) {        
      localStorage.removeItem("messageType"); 
      localStorage.removeItem("messagewithUsers");      
    }
  },
  methods: {
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                    //console.log(this.profileImg);
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    kyakka_modal(messageid) {
      console.log(messageid);
      message
        .listChild(messageid, "approval_message")
        .then((response) => {
          if (response != null) {
            this.approval_message = response.data.data[0].content;
            console.log(this.approval_message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    modalOpenDelete(messageid) {
      this.del_id = messageid;
    },
    deletemessage() {
      message
        .delete(this.del_id)
        .then((response) => {
          if (response != null) {
            console.log(response);
            /*
            this.messageList(
              this.message_type,
              this.get_post_num,
              this.$User.id,
              this.order,
              this.status
            );
            */
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    closeDiscardCom() {
      $("#confirmModalCom").removeClass("open");
      this.del_id = "";
    },
    messageList(type, id, num, order, status) {
      if(status === 'all'){
        status = ['公開','承認済','申請中','却下']
      }
      message
        .messagesList(type, id, num, order, status)
        // .listNumUser(type, num, id, order, status)
        .then((response) => {
          if (response != null) {
            this.messages = response.data.data;

            if(response.data.approvers!=null)
              this.approvedUsersList=response.data.approvers;

            this.total = response.data.total;
            this.messages.forEach((message_single) => {
              message
                .showmetakey(message_single.id, "key", "from_admin")
                .then((response) => {
                  if (response.data.data.length > 0) {
                    message_single.from_admin = response.data.data[0].value;
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {});

              if (
                this.message_type == "sdgs" ||
                this.message_type == "challenge"
              ) {
                let contentSplit = JSON.parse(message_single.content);

                message_single.content = striptags(contentSplit.content);
                message_single.content = message_single.content.replace(
                  /&nbsp;/g,
                  ""
                );

                message_single.content_1 = striptags(contentSplit.content_1);
                message_single.content_1 = message_single.content_1.replace(
                  /&nbsp;/g,
                  ""
                );

                message_single.content_2 = striptags(contentSplit.content_2);
                message_single.content_2 = message_single.content_2.replace(
                  /&nbsp;/g,
                  ""
                );

                message_single.option = contentSplit.option;
              } else if(this.message_type == "mysdgs"){
                message_single.content = JSON.parse(striptags(message_single.content))
                message_single.content.content = message_single.content.content.replace(/&nbsp;/g, '')
              }
              else {
                message_single.content = striptags(message_single.content);
                message_single.content = message_single.content.replace(
                  /&nbsp;/g,
                  ""
                );
              }
              let vueThis = this;
              if (message_single.target_ids != null) {
                message_single.target_user = [];
                vueThis.resGet = false;
                message_single.target_ids.forEach((user_id) => {
                  user
                    .show(user_id)
                    .then((response) => {
                      if (response != null) {
                        vueThis.resGet = true;
                        message_single.target_user.push(response.data.data);
                        $(".st_popup").each(function () {
                          $(this).click(function (e) {
                            $(".click_popup").css("display", "none");
                            $(this)
                              .find(".click_popup")
                              .css("display", "block");
                          });
                        });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                });
              }
              if (this.messagecolumn["eyecatch"]) {
                message
                  .showfile(message_single.id, "key", "eyecatch")
                  .then((response) => {
                    if (response != null) {
                      if (response.data.data.length != 0) {
                        message_single.eyecatch = response.data.data[0];
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageListmore() {
      $("#load").show();
      $("#more_btn").hide();

      this.now_post_num = this.now_post_num + this.get_post_num;
      if(this.status === 'all'){
        this.status = ['公開','承認済','申請中','却下']
      }
      message
        .messagesList(
          this.message_type,
          this.$User.id,
          this.now_post_num,
          this.order,
          this.status
        )
        .then((response) => {
          this.more_load = true;
          if (response != null) {
            this.messages = response.data.data;
            this.messages.forEach((message_single) => {
              message
                .showmetakey(message_single.id, "key", "from_admin")
                .then((response) => {
                  if (response != null) {
                    message_single.from_admin = response.data.data[0].value;
                  }
                })
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {});
              message_single.content = striptags(message_single.content);
              message_single.content = message_single.content.replace(
                /&nbsp;/g,
                ""
              );
              if (message_single.target_ids != null) {
                message_single.target_user = [];
                message_single.target_ids.forEach((user_id) => {
                  user
                    .show(user_id)
                    .then((response) => {
                      if (response != null) {
                        message_single.target_user.push(response.data.data);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {
                      this.loader = false;
                    });
                });
              }
              if (
                this.message_type == "sdgs" ||
                this.message_type == "challenge"
              ) {
                if (message_single.content.indexOf("{") != -1) {
                  let contentSplit = JSON.parse(message_single.content);
                  message_single.content = striptags(contentSplit.content);
                  message_single.content_1 = striptags(contentSplit.content_1);
                  message_single.content_2 = striptags(contentSplit.content_2);
                  message_single.option = contentSplit.option;
                } else {
                  message_single.content = null;
                  message_single.content_1 = null;
                  message_single.content_2 = null;
                  message_single.option = null;
                }
              }else if(this.message_type == "mysdgs"){
                message_single.content = JSON.parse(striptags(message_single.content))
                message_single.content.content = message_single.content.content.replace(/&nbsp;/g, '')
              }
               else {
                message_single.content = striptags(message_single.content);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    onOrderChange(event_order) {
      this.order = event_order.target.value;
      this.messageList(
        this.message_type,
        this.$User.id,
        this.get_post_num,
        this.order,
        this.status
      );
    },
    onStatusChange(event_status) {
      this.status = event_status.target.value;
      this.messageList(
        this.message_type,
        this.$User.id,
        this.get_post_num,
        this.order,
        this.status
      );
    },
    showYear(year) {
      if (typeof year == "number") {
        year = year.toString();
      }

      let setYear = "";
      switch (year) {
        case "2022":
          setYear = "49期 (2022)";
          break;
        case "2023":
          setYear = "50期 (2023)";
          break;
        case "2024":
          setYear = "51期 (2024)";
          break;
        case "2025":
          setYear = "52期 (2025)";
          break;
        case "2026":
          setYear = "53期 (2026)";
          break;
        default:
          setYear = year;
          break;
      }

      return setYear;
    },
    closeRejectMessagePopup(event) {
      $(".st_popup").each(function () {
        $(this).click(function (e) {
          $(".click_popup").css("display", "block");
          $(this).find(".click_popup").css("display", "none");
        });
      });
    },
  },
  mounted() {
    document.body.className = "page_control page_template page_sdgs";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      new ScrollHint(".js-scrollable", {
        suggestiveShadow: true,
        i18n: {
          scrollable: "スクロールできます",
        },
      });
      $(document).on("click", ".delete_button", function () {
        $("#confirmModalCom").fadeIn();
        $("#confirmModalCom").addClass("open");
      });
    });
  },
  updated() {
    if (this.more_load == true) {
      $("#load").hide();
      $("#more_btn").show();
      this.more_load = false;
    }
  },
  htmlText(msg) {
    return msg.replace(/\r?\n/g, "<br>");
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */

.reject-message {
  cursor: pointer;
}
.reject-message:after {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 32%; /* Change this to whatever width you want. */
  padding-top: 7px; /* This creates some space between the element and the border. */
  border-bottom: 3px solid orange; /* This creates the border. Replace black with whatever color you want. */
}
.close-icon {
  position: absolute;
  right: 20px;
  top: 12px;
  cursor: pointer;
}
</style>
